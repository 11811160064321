import { Image } from "@kanpla/ui";
import { Modal, Space } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import LoginInner from "./LoginInner";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  domain: string;
  color: string;
}

const LoginModal = ({ open, setOpen, name, domain, color }: Props) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      footer={false}
      title={
        <Space size="large">
          <Image
            src={`/images/posone/${domain}.png`}
            alt={`${name} logo`}
            className="w-10"
          />
          <p>{t("translation:log-in-via", { value: name })}</p>
        </Space>
      }
      centered
    >
      <LoginInner domain={domain} name={name} />
    </Modal>
  );
};

export default LoginModal;
