import { db } from "@kanpla/system";
import { PosOnePartnerData } from "@kanpla/types";
import { Image } from "@kanpla/ui";
import { Button, message, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import tinycolor from "tinycolor2";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import LoginModal from "./LoginModal";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Posone = ({ open, setOpen }: Props) => {
  const { t } = useTranslation(["login"]);
  const [posOnePartners = [], posOnePartnersLoading, posOnePartnersError] =
    useCollectionDataOnce<PosOnePartnerData>(db.collection("partnersPosOne"), {
      idField: "id",
    });

  const { customBranding } = useContainer(AppContext);

  useEffect(() => {
    posOnePartnersError &&
      !posOnePartnersLoading &&
      message.error(posOnePartnersError.message);
  }, [posOnePartnersError]);

  const singlePartner = posOnePartners?.find(
    (part) => part.prefix === customBranding?.url
  );

  if (singlePartner)
    return (
      <LoginModal
        open={open}
        setOpen={setOpen}
        name={singlePartner?.name}
        domain={singlePartner?.prefix}
        color={singlePartner?.color}
      />
    );

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      title={t("login:login-with-smart-payment")}
      centered
      footer={false}
    >
      <Space direction="vertical" className="w-full">
        {posOnePartners.map((partner) => (
          <PosoneButton
            key={partner.id}
            name={partner.name}
            domain={partner.prefix}
            color={partner.color}
          />
        ))}
      </Space>
    </Modal>
  );
};

export default Posone;

const PosoneButton = ({ name, domain, color }) => {
  const { t } = useTranslation(["translation"]);
  const textColor = tinycolor(color).isDark() ? "white" : "black";
  const lighterShade = tinycolor(color).brighten(6);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        className="w-full flex h-auto items-center"
        style={{
          background: `linear-gradient(to top, ${color}, ${lighterShade})`,
          border: "none",
          color: textColor,
        }}
        // color={color}
        onClick={() => setModalOpen(true)}
        size="large"
        icon={
          <Image
            src={`/images/posone/${domain}.png`}
            alt={`${name} logo`}
            className="w-8 mr-4"
          />
        }
      >
        <span>{t("translation:log-in-via", { value: name })}</span>
      </Button>
      <LoginModal
        open={modalOpen}
        setOpen={setModalOpen}
        name={name}
        domain={domain}
        color={color}
      />
    </>
  );
};
