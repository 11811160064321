import {
  AUTHENTICATION_MS,
  callFunction,
  configLabel,
  KanplaError,
} from "@kanpla/system";
import {
  ActionsAuthenticationModal,
  AnonymousPropsFlow,
  DataAuthenticationModal,
} from "@kanpla/types";
import { Button, Form, Input, message, Space } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Helpcenter from "../Helpcenter";
import ButtonSignUp from "../signup-flow/ButtonSignUp";
import PageHeader from "../signup-flow/PageHeader";
import PasswordInput from "./partial/PasswordInput";
import Posone from "./Posone";
import LoginInner from "./Posone/LoginInner";

const BasicLogin = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["login", "translation", "anonymous", "forms"]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    auth,
    customBranding,
    setModuleId,
    setOpenAuthenticationModal,
    setDataAuthenticationModal,
  } = useContainer(AppContext);

  const [posoneOpen, setPosoneOpen] = useState(false);

  const router = useRouter();

  const signIn = async () => {
    try {
      setLoading(true);

      if (!email || email === "")
        throw new KanplaError("kanpla/fill-email", "Fill out email address");

      await callFunction("signup-canAccessPartner", {
        email,
        partnerId: customBranding?.partnerId,
      });

      setModuleId(null);
      await auth.signIn(email, password);

      return true;
    } catch (err) {
      // User isn't created
      if (err?.code === "functions/not-found") {
        const noUserError = new KanplaError(
          "auth/user-not-found",
          "Firebase: Error (auth/user-not-found)."
        );

        message.error(noUserError.message);
        return;
      }

      // Can't log user in
      if (err?.code === "functions/unauthenticated") {
        console.error(err);
        message.error(t("translation:message.error.partner-locked"));
        return;
      }

      const newError = new KanplaError(err?.code, err?.message);
      console.error(err);
      message.error(newError.message);
    } finally {
      setLoading(false);
    }
  };

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal,
    isFromAPrivateModule?: boolean
  ) => {
    if (!isFromAPrivateModule) setOpenAuthenticationModal(false);
    setTimeout(() => {
      setDataAuthenticationModal(() => {
        const newState: DataAuthenticationModal = {
          title,
          subtitle,
          action,
        };
        return newState;
      });
      if (!isFromAPrivateModule) setOpenAuthenticationModal(true);
    }, AUTHENTICATION_MS);
  };

  return (
    <>
      <PageHeader
        title={t("translation:welcome-back")}
        subtitle={t("translation:log-in-to-your-account")}
      />
      <Form
        className="my-8 max-w-xs m-auto"
        onFinish={signIn}
        layout="vertical"
        // To catch the submit event on a custom form
        onSubmitCapture={(event) => event.preventDefault()}
      >
        {!customBranding?.posone && (
          <>
            <Form.Item
              name="email"
              label="Email"
              // labelCol={configLabel({ span: 24 })}
              rules={[
                {
                  required: true,
                  message: t("translation:form.errors.required", {
                    value: "email",
                  }),
                },
                {
                  type: "email",
                  message: t("translation:form.errors.invalid", {
                    value: "email",
                  }),
                },
              ]}
              initialValue={email}
            >
              <Input
                id="email"
                placeholder={t("translation:form.placeholders.email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <PasswordInput password={password} setPassword={setPassword} />
          </>
        )}

        <Space direction="vertical" className="w-full">
          {!customBranding?.posone && (
            <Form.Item>
              <ButtonSignUp
                title={t("translation:log-in")}
                loading={loading}
                loadingText={t("translation:loading")}
              />
            </Form.Item>
          )}
          {customBranding?.posone && customBranding?.url && (
            <LoginInner
              domain={customBranding?.url}
              name={customBranding?.name}
            />
          )}
          {customBranding?.posone && !customBranding?.url && (
            <ButtonSignUp
              title={t("login:login-with-smart-payment")}
              onClick={() => setPosoneOpen(true)}
              type="button"
            />
          )}
        </Space>
        <Posone open={posoneOpen} setOpen={setPosoneOpen} />
        {isFromAnonymousFlow ? (
          <span>
            {t("login:account-no-exist")}{" "}
            <span
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup"
                );
              }}
              className="font-semibold text-primary-main cursor-pointer"
            >
              {t("translation:get-one")}
            </span>
          </span>
        ) : isFromAPrivateModule ? (
          <span>
            {t("login:account-no-exist")}{" "}
            <span
              onClick={() => {
                setDataForAuthenticationModalAndOpenModal(
                  t("translation:sign-up"),
                  t("anonymous:modal.subtitles.info-authenticate-signup"),
                  "signup",
                  isFromAPrivateModule
                );
              }}
              className="font-semibold text-primary-main cursor-pointer"
            >
              {t("translation:get-one")}
            </span>
          </span>
        ) : !customBranding?.posone ? (
          <div className="mt-3 text-sm text-text-secondary">
            {t("translation:dont-have-account")}{" "}
            <Link
              href={{
                pathname: `/signup`,
                query: router.query,
              }}
            >
              <a className="font-semibold text-primary-main">
                {t("translation:get-one")}
              </a>
            </Link>
          </div>
        ) : (
          <a href={`https://${customBranding?.url}.smartbetaling.dk/#register`}>
            <Button size="large" className="w-full" type="default">
              {t("forms:form.buttons.create-account")}
            </Button>
          </a>
        )}
      </Form>

      <div className="mt-auto">
        <Helpcenter.Intercom small />
      </div>
    </>
  );
};

export default BasicLogin;
