import { auth, KanplaError } from "@kanpla/system";
import { Form, Input, message, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { signInWithCustomToken } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonSignUp from "../../signup-flow/ButtonSignUp";

interface Props {
  domain: string;
  name: string;
}

const LoginInner = ({ domain, name }: Props) => {
  const { t } = useTranslation(["login", "translation"]);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const token = await axios.post("/api/posone/login", {
        urlPrefix: domain,
        voucherCode: values.voucherCode,
        password: values.password,
      });

      await signInWithCustomToken(auth, token.data.token);
      message.success(t("login:message.success.will-logged"));
    } catch (e) {
      if (e?.response?.data?.code) {
        const newError = new KanplaError(
          e.response.data.code,
          e.response.data.message
        );
        message.error(newError?.message);
      } else {
        message.error(e.message);
      }
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Space direction="vertical" className="w-full">
        <Form.Item
          label={t("login:form.labels.short-id", { value: name })}
          name="voucherCode"
          hasFeedback
          rules={[
            {
              required: true,
              message: t("login:form.errors.required", {
                value: t("login:values.card-id"),
              }),
            },
            {
              pattern: /^\d{8,10}$/,
              message: t("login:form.errors.invalid"),
            },
          ]}
        >
          <Input
            placeholder="1234567890"
            size="large"
            autoComplete="off"
          ></Input>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t("login:form.errors.required", {
                value: t("login:values.password"),
              }),
            },
          ]}
          label={t("login:form.labels.your-password")}
        >
          <Input
            type="password"
            placeholder={t("login:form.placeholders.your-password")}
            size="large"
            onKeyPress={(key) => {
              if (key.code === "Enter") form.submit();
            }}
          />
        </Form.Item>
        <Form.Item>
          <ButtonSignUp
            title={t("translation:log-in")}
            loading={loading}
            type="button"
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default LoginInner;
